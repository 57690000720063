import ApiService from "@/core/services/api.service";
//import i18nService from "@/core/services/i18n.service.js";
import SiteService from "@/core/services/site.service.js";

const state = {
    errors: null,
    collection: []
};

const getters = {
    newsAuthorCollection(state) {
        return state.collection;
    },

    getNewsAuthorByID: (state) => (id) => {
        let item = state.collection.find((item) => item.id == id);
        if (!item) {
          item = null;
        }
        return item;
    }
};

const actions = {
    fetchNewsAuthor(context) {
        return new Promise((resolve) => {
            //let appLocale = i18nService.getActiveLanguage();
            ApiService.get(
                "news/admin/" + SiteService.getActiveSiteId() + "/news-author"
            ).then(({ data }) => {
              context.commit("fetchNewsAuthor", data);
              resolve();
          });
        }).catch((error) => {
            console.log("Error!: ", error);
        });
    }
};

const mutations = {
    fetchNewsAuthor(state, data) {
      state.collection = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};