import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import i18nService from "@/core/services/i18n.service.js";
import router from "../../../router.js";
import SiteService from "@/core/services/site.service.js";
// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

var timeOut = null;
var timeOutReboot = null;

const state = {
  errors: {},
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          // context.commit(SET_AUTH, data);
          // resolve(data);
          if (data.success) {
            context.commit(SET_AUTH, data);
            window.localStorage.removeItem("permissions");
            resolve(data);
          } else {
            context.commit(SET_ERROR, [data.message]);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          if (response) {
            context.commit(SET_ERROR, [response.data.message]);
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (localStorage.getItem("id_token") != null) {
      // if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          if (data) {
            context.commit(SET_AUTH, data);
            context.dispatch("getConfig");
            if (!localStorage.getItem("selectedLanguage")) {
              localStorage.setItem("selectedLanguage", data.locale);
              i18nService.setActiveLanguage(data.locale);
            }

            clearTimeout(timeOut);
            timeOut = setTimeout(function () {
              context.dispatch("openExpiredDialog");
            }, 3300000);

            clearTimeout(timeOutReboot);
            timeOutReboot = setTimeout(function () {
              context.commit(PURGE_AUTH);
              window.location.href = window.location.origin;
            }, 3600000);
          } else {
            context.commit(SET_ERROR, ["Invalid Email or Password"]);
            context.commit(PURGE_AUTH);
            router.go(router.login);
          }
        })
        .catch(({ response }) => {
          if (!response || response.status == 401) {
            context.commit(PURGE_AUTH);
            router.go(router.login);
          }
          context.commit(SET_ERROR, [response]);
        });
    } else {
      if (state.isAuthenticated == true) {
        context.commit(PURGE_AUTH);
        router.go(router.login);
      }
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    // return ApiService.put("user", user).then(({ data }) => {
    //   context.commit(SET_AUTH, data);
    //   return data;
    // });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // JwtService.saveToken('state.user.token');
    if (state.user.token) {
      JwtService.saveToken(state.user.token);
    }
  },
  [PURGE_AUTH](state) {
    clearTimeout(timeOutReboot);
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    SiteService.setActiveSiteId(1);
    localStorage.removeItem("selectedLanguage");
    localStorage.removeItem("languages");
    localStorage.removeItem("selectedUserLanguage");
    window.localStorage.removeItem("permissions");
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
